<template>
  <section>

    <b-card>
      <b-row>
        <b-col>
          <b-button-group>
            <b-button
              v-for="btn in licencesTypes"
              :key="btn.value"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              :pressed="btn.value === activeFilter"
              @click="activeFilter = btn.value"
            >
              {{ btn.text }}
            </b-button>

          </b-button-group>
        </b-col>
        <b-col class="text-md-right mt-md-0 mt-1 text-left">
          <b-button-group
            class="d-none d-md-block"
          >
            <b-button
              v-for="btn in licencesStatuses"
              :key="btn.value"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              :pressed="btn.value === statusFilter"
              @click="statusFilter = btn.value"
            >
              {{ btn.text }}
            </b-button>

          </b-button-group>
          <v-select
            id="status"
            v-model="statusFilter"
            class="d-md-none"
            label="text"
            :reduce="o => o.value"
            :clearable="false"
            :options="licencesStatuses"
          />
        </b-col>
      </b-row>
    </b-card>

    <licence-table
      title="Licences"
      :rows="filteredLicences"
      :columns="columns"
      :page-length="10"
      @updated="update"
    />
  </section>
</template>

<script>
import axios from '@axios'
import {
  BRow, BCol, BCard, BCardText, BListGroup, BListGroupItem, BButton, BFormSpinbutton, BFormCheckbox, BButtonGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import LicenceTable from '../../components/LicenceTable.vue'

/* eslint-disable */
export default {
  components: {
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BFormSpinbutton,
    LicenceTable,
    BFormCheckbox,
    BButtonGroup,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      activeFilter: 'all',
      statusFilter: 'all',
      filters: {},
      licences: [],
    }
  },
  computed: {
    columns() {
      return [
        {
          label: 'ID',
          field: 'redeem_code',
        },
        {
          label: this.$t('Type'),
          field: 'license_type',
           formatFn: this.licenceName,
        },
        {
          label: this.$t('Available Date'),
          field: 'available_date',
        },
        {
          label: this.$t('Expire Date'),
          field: 'expire_date',
        },
        {
          label: this.$t('Approval Date'),
          field: 'approve_date',
        },
        {
          label: this.$t('Spider Email Account'),
          field: 'email',
          tdClass: 'text-center',
        },
      ]
    },
    licencesTypes() {
      return [
        {
          text: this.$t('All'),
          value: 'all',
        },
        {
          text: this.$t('Performance'),
          value: 'LITE',
        },
        {
          text: this.$t('Tour'),
          value: 'STANDARD',
        },
      ]
    },
    licencesStatuses() {
      return [
        {
          text: this.$t('All'),
          value: 'all',
        },
        {
          text: this.$t('Available'),
          value: 'available',
        },
        {
          text: this.$t('Not accepted yet'),
          value: 'not-accepted',
        },
        {
          text: this.$t('Used'),
          value: 'used',
        },
        {
          text: this.$t('Expired'),
          value: 'expired',
        },
      ]
    },
    filteredLicences() {
      if (this.activeFilter !== 'all'){
        return this.licences.filter(item => item.license_type === this.activeFilter).filter(item => {
          if (this.statusFilter === 'all') {
            return true
          }
          if (this.statusFilter === 'available') {
            return !item.email && Date.parse(item.available_date) >= Date.now()
          }
          if (this.statusFilter === 'not-accepted') {
            return item.email && !item.approve_date
          }
          if (this.statusFilter === 'used') {
            return item.email && item.approve_date
          }
          if (this.statusFilter === 'expired') {
            return Date.parse(item.expire_date) < Date.now() || Date.parse(item.available_date) < Date.now()
          }
        })
      }
      return this.licences.filter(item => {
        if (this.statusFilter === 'all') {
          return true
        }
        if (this.statusFilter === 'available') {
          return !item.email && Date.parse(item.available_date) >= Date.now()
        }
        if (this.statusFilter === 'not-accepted') {
          return item.email && !item.approve_date
        }
        if (this.statusFilter === 'used') {
          return item.email && item.approve_date
        }
        if (this.statusFilter === 'expired') {
          return Date.parse(item.expire_date) < Date.now() || Date.parse(item.available_date) < Date.now()
        }
      })
    },
  },
  created() {
    axios.get('/api/subscription/licences/').then(response => {
      this.licences = response.data
    })
      // .catch(() => {
      //   this.$store.dispatch('spider/redirectToLogin')
      // })
  },
  methods: {
    licenceName(value) {
      if (value === 'LITE') {
        return this.$t('Performance')
      }
      if (value === 'STANDARD') {
        return this.$t('Tour')
      }
      return value
    },
    update() {
      axios.get('/api/subscription/licences/').then(response => {
      this.licences = response.data
    })
      .catch(() => {
        this.$store.dispatch('spider/redirectToLogin')
      })
    }
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
